import React from "react";

export default class CheckboxInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.initialValue
    };
  }

  update = checked => {
    let dict = {};
    dict[this.props.name] = checked;
    this.props.onAttributeUpdate(dict);
    this.setState({ checked });
  };

  renderInput = () => {
    const {
      name,
      placeholder,
      inputStyle,
      inputClass
    } = this.props;

    return (
      <input
        id={name}
        style={inputStyle || inputDefaultStyle}
        placeholder={placeholder}
        className={inputClass}
        type="checkbox"
        onChange={e => this.update(e.target.checked)}
        checked={this.state.value}
      />
    );
  };

  render() {
    const {
      name,
      label,
      containerStyle,
      containerClass,
      labelStyle,
      labelClass
    } = this.props;
    return (
      <div
        style={containerStyle || containerDefaultStyle}
        className={containerClass}
      >
        {this.renderInput()}
        <label
          htmlFor={name}
          style={labelStyle || labelDefaultStyle}
          className={labelClass}
        >
          {label}
        </label>
      </div>
    );
  }
}

const labelDefaultStyle = {
  textAlign: "left",
  display: "block"
};

const inputDefaultStyle = {
  width: "25px",
  height: "25px",
  float: "left",
  marginRight: "2em"
};

const containerDefaultStyle = {
  overflow: "auto",
  display: "block",
  margin: "0.75em 0"
};