export default {
  fields: {
    includePhoto: false,
    includeDiving: false,
    diverCount: 1,
    divers: [],
    specialRequest: null
  },
  errors: {
    divers: [{}]
  },
  diverDetails: {
    name: null,
    license: null,
    feetSize: "M",
    wetSuitSize: "M"
  },
  diverDetailsError: {}
};
