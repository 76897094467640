import moment from "moment";

export function validators(newAttributes) {
  return Object.keys(newAttributes).reduce((errors, attribute) => {
    errors[attribute] =
      attributeValidators[attribute] &&
      attributeValidators[attribute](newAttributes[attribute]);
    return errors;
  }, {});
}

export function compoundValidator(fields, reservedDates) {
  let errors = {};

  errors["checkoutDate"] =
    !(fields.tripType !== "liveAboard" || fields.checkoutDate) && "required";

  errors["checkinDate"] =
    !(
      (fields.tripType !== "liveAboard" &&
        !reservedDates[fields.tripType].includes(
          moment(fields.checkinDate).format("YYYY-MM-DD")
        )) ||
      (fields.tripType === "liveAboard" &&
        reservedDates["liveAboard"].every(
          d =>
            !moment(d).isBetween(
              fields.checkinDate,
              fields.checkoutDate,
              null,
              "[]"
            )
        ))
    ) && "selected date is unavailable";

  return errors;
}

const attributeValidators = {
  contactName: val => !val && "required",
  guestName: val => !val && "required",
  email: val =>
    !(
      val &&
      /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
        val
      )
    ) && "invalid email address",
  phone: val => !(val && /[0-9]{9,14}/.test(val)) && "invalid phone number",
  identityNo: val =>
    !(val && /[a-zA-Z0-9]{3,24}/.test(val)) && "invalid identity number",
  checkinDate: val => !val && "required"
};
