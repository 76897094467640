import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  convertToIDR,
  getTripTypeLabel,
  getPhotoPackageLabel,
  getTripPrice,
  getPhotoPrice,
  getDivingPrice,
  getTotalPrice
} from "../../utils/helpers";

class PriceBreakdown extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      convertionRate: 14000,      
    }  
  }

  async componentDidMount() {
    try{
      let url = "https://api.exchangeratesapi.io/latest?base=USD&symbols=IDR";    
      let convertionRateResp = await fetch(url, {
        method: "GET",
        headers: {
          "Accept": "application/json"
        }}
      );
      if (!convertionRateResp.ok) throw Error("error on " + url);

      let convertionRate = await convertionRateResp.json();
      this.setState({convertionRate: convertionRate['rates']['IDR']})
    } catch(error) {
      console.log(error);
    }
  }

  render() {
    let { reservation, addons, prices } = this.props;
    return (
      <div>
        <h5 style={{ marginBottom: "25px" }}>Price Details</h5>
        {reservation && (
          <Row>
            <Col>{getTripTypeLabel(reservation.tripType)}</Col>
            <Col xs={4} style={{ textAlign: "right" }}>
              {getTripPrice(prices, reservation.tripType)}
            </Col>
          </Row>
        )}
        {addons && addons.includePhoto && addons.photoPackage && (
          <Row>
            <Col>{getPhotoPackageLabel(addons.photoPackage)} Photo</Col>
            <Col xs={4} style={{ textAlign: "right" }}>
              {getPhotoPrice(prices, addons.photoPackage)}
            </Col>
          </Row>
        )}
        {addons && addons.includeDiving && addons.diverCount && (
          <Row>
            <Col>Diving {addons.diverCount} pax</Col>
            <Col xs={4} style={{ textAlign: "right", "color": "white" }}>
              {getDivingPrice(prices, addons.diverCount)}
            </Col>
          </Row>
        )}
        <hr />
        {this.props.hideTotal || (
          <Row style={{ fontWeight: "bold" }}>
            <Col>Total</Col>
            <Col xs={6} style={{ textAlign: "right" }}>
              <p>USD {getTotalPrice(prices, reservation, addons)}</p>
              <p>IDR {convertToIDR(this.state.convertionRate, getTotalPrice(prices, reservation, addons))}</p>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  reservation: state.reservation,
  addons: state.addons,
  prices: state.prices
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PriceBreakdown)
);
