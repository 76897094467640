import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import HomePage from "./components/pages/HomePage";
import ReservationPage from "./components/pages/ReservationPage";
import AddonsPage from "./components/pages/AddonsPage";
import ThankYouPage from "./components/pages/ThankYouPage";
import AdminPage from "./components/pages/AdminPage";

class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/booked" component={AdminPage} />
            <Route exact path="/reservation" component={ReservationPage} />
            <Route exact path="/addons" component={AddonsPage} />
            <Route exact path="/thankyou" component={ThankYouPage} />
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
