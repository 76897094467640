import React from "react";

export default class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue || "",
      touched: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      error: nextProps.error,
      touched: this.state.touched || nextProps.error
    });
  }

  onChange = e => {
    this.setState({ value: e.target.value });
    if (this.state.touched) this.update(e.target.value);
  };

  onBlur = e => {
    this.update(this.state.value);
  };

  update = (newValue = null) => {
    let dict = {};
    dict[this.props.name] = newValue || this.state.value;
    this.props.onAttributeUpdate(dict);
  };

  renderInput = () => {
    const { value, error } = this.state;
    const { placeholder, type, inputStyle, inputClass, min, max } = this.props;

    if (type === "textarea")
      return (
        <textarea
          style={{
            ...{ ...inputDefaultStyle, ...inputStyle },
            ...{ borderColor: error && "red" }
          }}
          placeholder={placeholder}
          className={inputClass}
          type={type}
          onBlur={this.onBlur}
          onChange={this.onChange}
          value={value}
        />
      );

    return (
      <input
        style={{
          ...{ ...inputDefaultStyle, ...inputStyle },
          ...{ borderColor: error && "red" }
        }}
        placeholder={placeholder}
        className={inputClass}
        type={type}
        onBlur={this.onBlur}
        onChange={this.onChange}
        value={value}
        min={type === "date" ? min : undefined}
        max={type === "date" ? max : undefined}
      />
    );
  };

  render() {
    const { error } = this.state;
    const {
      label,
      containerStyle,
      containerClass,
      labelStyle,
      labelClass,
      errorStyle
    } = this.props;
    return (
      <div
        style={containerStyle || containerDefaultStyle}
        className={containerClass}
      >
        <label style={labelStyle || labelDefaultStyle} className={labelClass}>
          {label}
        </label>
        {this.renderInput()}
        <p style={errorStyle || errorDefaultStyle}>{error ? error : ""}</p>
      </div>
    );
  }
}

const labelDefaultStyle = {
  textAlign: "left",
  display: "block"
};

const inputDefaultStyle = {
  color: "rgba(59, 58, 58, 0.55)",
  display: "block",
  width: "100%",
  height: "3em",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "rgba(59, 58, 58, 0.55)",
  backgroundColor: "transparent",
  paddingLeft: "0.5em",
  textAlign: "top"
};

const containerDefaultStyle = {
  overflow: "auto",
  display: "block"
};

const errorDefaultStyle = {
  color: "red",
  minHeight: "1em",
  fontSize: "0.8em",
  marginTop: "3px",
  marginBottom: "1em",
  textAlign: "left",
  width: "70%"
};
