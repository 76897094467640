import React from "react";
import { Button, Modal, Row, Col, Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../../common/Input";
import initialState from "./initialState";
import { validators, compoundValidator } from "./validators";
import { withRouter } from "react-router-dom";
import { CountryCodes } from "../../../utils/Constants";
import {
  range,
  getTripTypeLabel,
  getTotalPrice,
  getTripPrice
} from "../../../utils/helpers";
import { putReservation } from "../../../utils/actions";
import DropdownInput from "../../common/DropdownInput";
import qs from "query-string";
import PriceBreakdown from "../../common/PriceBreakdown";
import SelectTripTypePage from "../../pages/SelectTripTypePage";
import moment from "moment";
import DateInput from "../../common/DateInput";

class ReservationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: initialState.errors,
      showModal: false
    };
    this.updateAttributes.bind(this);
  }

  componentDidMount() {
    let tripType =
      qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      }).type || this.props.fields.tripType;
    this.props.putReservation({ ...this.props.fields, tripType });
  }

  updateAttributes = newAttributes => {
    let newErrors = validators(newAttributes);

    if (newAttributes.tripType) this.setState({ showModalTripType: false });

    if (newAttributes.tripType && newAttributes.tripType !== "liveAboard")
      newAttributes.checkoutDate = null;

    let fields = { ...this.props.fields, ...newAttributes };
    let errors = { ...this.state.errors, ...newErrors };
    this.props.putReservation(fields);
    this.setState({ errors, submitError: false });
  };

  parsePhone = (countryCode, phone) => {
    if (phone[0] === "0") phone = phone.substring(1);
    return countryCode + phone;
  };

  validateAll = attributes => {
    let errors = validators(attributes);
    this.setState({ errors });
    return !Object.keys(errors).reduce(
      (result, key) => result || errors[key],
      false
    );
  };

  compoundValidate = () => {
    let errors = compoundValidator(this.props.fields, this.props.reservedDates);
    this.setState({ errors });
    return !Object.keys(errors).reduce(
      (result, key) => result || errors[key],
      false
    );
  };

  submitForm = () => {
    let validated =
      this.validateAll(this.props.fields) && this.compoundValidate();
    if (!validated) {
      this.setState({ submitError: true });
      return;
    }

    let payload = { ...this.props.fields };
    payload.phone = this.parsePhone(payload.countryCode, payload.phone);
    this.props.putReservation(payload);
    this.props.history.push("/addons");
  };

  dateBlock = date => {
    let tripType = this.props.fields.tripType;
    let { reservedDates } = this.props;
    date = moment(date).format("YYYY-MM-DD");
    return reservedDates[tripType].includes(date);
  };

  render() {
    const { errors, showBreakdown } = this.state;
    const { fields, prices } = this.props;
    return (
      <div style={{ boxStyle }}>
        <Row>
          <Col>
            <h3>Trip Package</h3>
            <h5>
              {`${getTripTypeLabel(fields.tripType)} - USD 
              ${getTripPrice(prices, fields.tripType)}`}
            </h5>
            <Row
              onClick={() => this.setState({ showModalTripType: true })}
              style={{ marginBottom: "10px" }}
            >
              <Col>
                <p style={{ color: "teal" }}>Change</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Select Date</h3>
          </Col>
        </Row>
        <Row>
          {fields.tripType !== "liveAboard" && (
            <Col>
              <DateInput
                name="checkinDate"
                label="Date"
                type="single"
                initialValue={fields.checkinDate}
                error={errors.checkinDate}
                onAttributeUpdate={this.updateAttributes}
                dateBlock={this.dateBlock}
              />
            </Col>
          )}
          {fields.tripType === "liveAboard" && (
            <Col>
              <DateInput
                startName="checkinDate"
                endName="checkoutDate"
                label="Duration"
                type="range"
                initialStartValue={fields.checkinDate}
                initialEndValue={fields.checkoutDate}
                error={errors.checkinDate || errors.checkoutDate}
                onAttributeUpdate={this.updateAttributes}
                dateBlock={this.dateBlock}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <h3>Contact Information</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              name="contactName"
              label="Name"
              type="text"
              placeholder="Full Name"
              initialValue={fields.contactName}
              error={errors.contactName}
              onAttributeUpdate={this.updateAttributes}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Input
              name="email"
              label="Email Address"
              type="email"
              placeholder="email@example.com"
              initialValue={fields.email}
              error={errors.email}
              onAttributeUpdate={this.updateAttributes}
            />
          </Col>
          <Col lg={3} xs={6}>
            <DropdownInput
              name="countryCode"
              label="Phone Number"
              dropdownItems={CountryCodes}
              initialValue={fields.countryCode}
              onAttributeUpdate={this.updateAttributes}
            />
          </Col>
          <Col lg={3} xs={6}>
            <Input
              name="phone"
              label="&nbsp;"
              type="text"
              placeholder="Phone Number"
              initialValue={fields.phone}
              error={errors.phone}
              onAttributeUpdate={this.updateAttributes}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Guest Information</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <Input
              name="guestName"
              label="Main Guest's Full Name"
              type="text"
              placeholder="Full Name"
              initialValue={fields.guestName}
              error={errors.guestName}
              onAttributeUpdate={this.updateAttributes}
            />
          </Col>
          <Col lg={5}>
            <Input
              name="identityNo"
              label="Identity Number"
              type="text"
              placeholder="Nationality ID Number/Passport"
              initialValue={fields.identityNo}
              error={errors.identityNo}
              onAttributeUpdate={this.updateAttributes}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Number of Guest</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={4} xs={6}>
            Adults
            <DropdownInput
              name="adultCount"
              dropdownItems={range(1, 29)}
              initialValue={fields.adultCount}
              onAttributeUpdate={this.updateAttributes}
            />
          </Col>
          <Col lg={4} xs={6}>
            Children
            <DropdownInput
              name="childCount"
              dropdownItems={range(0, 28)}
              initialValue={fields.childCount}
              onAttributeUpdate={this.updateAttributes}
            />
          </Col>
        </Row>
        <div
          style={{ marginBottom: "1.5em" }}
          onClick={() => this.setState({ showBreakdown: !showBreakdown })}
          className="d-block d-lg-none"
        >
          <Collapse in={showBreakdown}>
            <div>
              <PriceBreakdown hideTotal />
            </div>
          </Collapse>
          <Row>
            <Col style={{ fontWeight: "bold" }}>Total</Col>
            <Col xs={6} style={{ textAlign: "right", fontWeight: "bold" }}>
              USD {getTotalPrice(prices, fields)}
            </Col>
          </Row>
          <p style={{ textAlign: "right", color: "teal" }}>See details</p>
        </div>
        {this.state.submitError && (
          <p style={{ color: "red" }}>
            There are errors with your data, please check the form above.
          </p>
        )}
        <Row>
          <Col>
            <Button onClick={this.submitForm} style={buttonStyle}>
              Next
            </Button>
          </Col>
        </Row>
        <Modal
          centered
          size="lg"
          show={this.state.showModalTripType}
          onHide={() => this.setState({ showModalTripType: false })}
        >
          <Modal.Body>
            <SelectTripTypePage
              name="tripType"
              onAttributeUpdate={this.updateAttributes}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  fields: ownProps.fields || state.reservation || initialState.fields,
  prices: state.prices,
  reservedDates: state.reservedDates
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  putReservation: reservation => dispatch(putReservation(reservation))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReservationForm)
);

const buttonStyle = {
  backgroundColor: "rgba(182, 243, 232, 1)",
  border: "solid rgba(182, 243, 232, 1) 0px",
  borderRadius: "50px",
  height: "50px",
  width: "142px",
  color: "black",
  float: "right",
  fontWeight: "bold"
};

const boxStyle = {
  backgroundColor: "#ededed",
  borderRadius: "10px"
};
