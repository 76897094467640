import React, { Component } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import PhotoCard from "../common/PhotoCard";
import { PhotoPackageItems, nextIcon, prevIcon } from "../../utils/Constants";

export default class SelectPhotoPage extends Component {
  onSelect = selection => {
    let newAttributes = {};
    newAttributes[this.props.name] = selection;
    this.props.onAttributeUpdate(newAttributes);
  };

  renderItems = items =>
    items.map(item => (
      <PhotoCard
        key={item.value}
        img={item.img}
        title={item.label}
        content={item.content}
        value={item.value}
        onSelect={() => this.onSelect(item.value)}
      />
    ));

  render() {
    return (
      <div>
        <div className="d-none d-lg-block">
          <Container>
            <Row>
              {this.renderItems(PhotoPackageItems).map(item => (
                <Col key={item.key}>{item}</Col>
              ))}
            </Row>
          </Container>
        </div>
        <div className="d-block d-lg-none">
          <Container>
            <Row>
              <Col>
                <Carousel
                  indicators={null}
                  nextIcon={
                    <span
                      className="carousel-control-next-icon"
                      style={{ backgroundImage: nextIcon }}
                    />
                  }
                  prevIcon={
                    <span
                      className="carousel-control-prev-icon"
                      style={{ backgroundImage: prevIcon }}
                    />
                  }
                >
                  {this.renderItems(PhotoPackageItems).map(item => (
                    <Carousel.Item key={item.key}>
                      <div>
                        <Row className="justify-content-center">
                          <Col xs="auto">{item}</Col>
                        </Row>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
