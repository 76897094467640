import React from "react";

export default class DropdownInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue
    };
  }

  update = value => {
    let dict = {};
    dict[this.props.name] = value || this.state.value;
    this.props.onAttributeUpdate(dict);
    this.setState({ value });
  };

  renderInput = () => {
    const { value } = this.state;
    const { inputStyle, inputClass } = this.props;

    return (
      <select
        style={inputStyle || inputDefaultStyle}
        className={inputClass}
        type="dropdown"
        onChange={e => this.update(e.target.value)}
        value={value}
      >
        {/* <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={e => this.setState({ dropdownFilter: e.target.value })}
            value={this.state.dropdownFilter}
          /> */}
        {this.props.dropdownItems
          // .filter(
          //   v =>
          //     !this.state.dropdownFilter ||
          //     v.label.startsWith(this.state.dropdownFilter)
          // )
          .map(v => (
            <option
              value={v.value || v}
              key={(v.value || v) + (v.label || v.value || v)}
            >
              {v.label || v.value || v}
            </option>
          ))}
      </select>
    );
  };

  render() {
    const {
      label,
      containerStyle,
      containerClass,
      labelStyle,
      labelClass
    } = this.props;
    return (
      <div
        style={containerStyle || containerDefaultStyle}
        className={containerClass}
      >
        <label style={labelStyle || labelDefaultStyle} className={labelClass}>
          {label}
        </label>
        {this.renderInput()}
      </div>
    );
  }
}

const labelDefaultStyle = {
  textAlign: "left",
  display: "block"
};

const inputDefaultStyle = {
  color: "rgba(59, 58, 58, 0.55)",
  display: "block",
  width: "100%",
  height: "3em",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "rgba(59, 58, 58, 0.55)",
  backgroundColor: "transparent",
  paddingLeft: "0.5em"
};

const containerDefaultStyle = {
  overflow: "auto",
  display: "block",
  marginBottom: "2em"
};
