import React from "react";
import "./Template.css";
import { Row, Col, Container } from "react-bootstrap";

export const Footer = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} style={{ textAlign: "center" }}>
          <p className="font_7">
            <span style={{ fontSsize: "14px" }}>
              For any inquiries, questions or commendations, please call: (+62) 3614701133 or WA: (+62) 81337567123
              Email: {" "}
              <a
                data-content="behike.luxuryphinisi@gmail.com"
                href="mailto:behike.luxuryphinisi@gmail.com"
                data-type="mail"
              >
              behike.luxuryphinisi@gmail.com
              </a>
            </span>
          </p>
          <p className="font_9">
            <span>© 2019&nbsp;by Behike</span>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export const Header = () => {
  return (
    <Container fluid>
      <Row className="header-container">
        <Col>
          <a href="http://behikeyacht.com">
            <img className="header-logo" src="asset/images/behike_logo.png" alt='logo'/>
          </a>
          <div style={{ width: "230px" }} className="header-item">
            <h1
              className="font_0"
              style={{ fontSize: "15px", lineHeight: "1em", marginTop: "10px" }}
            >
              <span style={{ color: "#000000" }}>
                <span style={{ letterSpacing: "0.05em" }}>
                  <a
                    style={{ color: "#000000" }}
                    href="http://behikeyacht.com"
                    target="_self"
                  >
                    LUXURY PEARL THROUGH
                  </a>
                </span>
              </span>
            </h1>
            <h1
              className="font_0"
              style={{ fontSize: "15px", lineHeight: "1em" }}
            >
              <span style={{ color: "#000000" }}>
                <span style={{ letterSpacing: "0.05em" }}>THE ARCHIPELAGO</span>
              </span>
            </h1>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
