import React from "react";
import { Button, Modal, Row, Col, Collapse, Container } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../../common/Input";
import initialState from "./initialState";
import { validators } from "./validators";
import { withRouter } from "react-router-dom";
import { apiHost } from "../../../env";
import Dive from "../../common/Dive";
import {
  range,
  getPhotoPackageLabel,
  getTotalPrice,
  getPhotoPrice,
  getDivingPrice
} from "../../../utils/helpers";
import { putAddons } from "../../../utils/actions";
import CheckboxInput from "../../common/CheckboxInput";
import DropdownInput from "../../common/DropdownInput";
import PaypalButton from "./PaypalButton";
import {
  makeReservationPayload,
  makePersonsPayload
} from "../../../utils/payloads";
import PriceBreakdown from "../../common/PriceBreakdown";
import SelectPhotoPage from "../../pages/SelectPhotoPage";

class AddonsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: initialState.errors,
      showModal: false,
      showModalPayment: false,
      snapToken: "",
      bookCode: ""
    };
    this.updateAttributes.bind(this);
  }

  updateAttributes = newAttributes => {
    let newErrors = validators(newAttributes);
    let divers = this.props.fields.divers;
    let diverErrors = this.state.errors.divers;

    if (newAttributes.includePhoto === false) newAttributes.photoPackage = null;
    if (newAttributes.includePhoto === true)
      this.setState({ showModalPhoto: true });

    if (newAttributes.photoPackage) this.setState({ showModalPhoto: false });

    if (newAttributes.includeDiving === false) newAttributes.diverCount = 0;
    if (newAttributes.includeDiving === true) newAttributes.diverCount = 1;

    if (newAttributes.diverCount != null) {
      let newDivers = [];
      let newDiverErrors = [];
      for (let i = 0; i < newAttributes.diverCount; i++) {
        newDivers[i] = divers[i] || initialState.diverDetails;
        newDiverErrors[i] = diverErrors[i] || initialState.diverDetailsError;
      }
      divers = newDivers;
      diverErrors = newDiverErrors;
    }

    if (newAttributes.divers) {
      Object.keys(newAttributes.divers).forEach(i => {
        divers[i] = { ...divers[i], ...newAttributes["divers"][i] };
        diverErrors[i] = { ...diverErrors[i], ...newErrors["divers"][i] };
      });
    }

    let fields = { ...this.props.fields, ...newAttributes, divers };
    let errors = { ...this.state.errors, ...newErrors, divers: diverErrors };
    this.setState({ errors, submitError: false });
    this.props.putAddons(fields);
  };

  validateAll = attributes => {
    let errors = validators(attributes);
    this.setState({ errors });
    return !Object.keys(errors).reduce(
      (result, key) =>
        key === "divers"
          ? Object.keys(errors[key]).reduce(
              (result2, idx) =>
                result2 ||
                Object.keys(errors[key][idx]).reduce(
                  (result3, key3) => result3 || errors[key][idx][key3],
                  false
                ),
              false
            )
          : result || errors[key],
      false
    );
  };

  goBack = () => {
    this.props.history.push("/reservation");
  };

  reserve = async () => {
    let validated = this.validateAll(this.props.fields);
    if (!validated) {
      this.setState({ submitError: true });
      return;
    }

    this.setState({
      showModal: true,
      showModalPayment: false,
      modalContent: "Please wait a moment...",
      modalDismissable: false
    });
    let addons = this.props.fields;
    let reservationPayload = makeReservationPayload(
      this.props.reservation,
      addons
    );
    console.log(reservationPayload)

    let res1 = await fetch(apiHost + "/reservations", {
      method: "POST",
      body: JSON.stringify(reservationPayload),
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (!res1.ok) throw Error("error on /reservations: " + res1.body);

    let data1 = await res1.json();
    if (!data1.available) throw Error("date not available");

    let bookCode = data1.generatedBookCode;
    let snapToken = data1.snapToken;

    if (addons.includeDiving) {
      let personsPayload = makePersonsPayload(bookCode, addons);

      let res2 = await fetch(apiHost + "/persons", {
        method: "POST",
        body: JSON.stringify(personsPayload),
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (!res2.ok) throw Error("error on /persons: " + res2.body);
    }
    this.setState({
      showModal: false,
      showModalPayment: true,
      snapToken: snapToken,
      bookCode: bookCode
    });
  };

  submitLocalPayment = () => {
    try {
      window.snap.pay(this.state.snapToken);
    } catch (error) {
      this.setState({
        showModal: true,
        modalContent:
          "Reservation unsuccessful. Please contact our customer support.",
        modalDismissable: true
      });
    }
  };

  renderDiverForms = count => {
    let result = [];
    for (let i = 0; i < count; i++) {
      result.push(
        <Dive
          idx={i}
          key={i}
          containerClass="dive"
          initialValue={this.props.fields.divers[i]}
          error={this.state.errors.divers[i]}
          onAttributeUpdate={this.updateAttributes}
        />
      );
    }
    return result;
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { errors, showBreakdown } = this.state;
    const { fields, reservation, prices } = this.props;
    return (
      <div>
        <h2>Spice up your journey with these add-ons!</h2>
        <Row>
          <Col>
            <CheckboxInput
              name="includePhoto"
              initialValue={fields.includePhoto}
              onAttributeUpdate={this.updateAttributes}
              label={<h4>Include Photo</h4>}
            />
          </Col>
        </Row>
        {fields.includePhoto && fields.photoPackage && (
          <div>
            <h5>
              {`${getPhotoPackageLabel(fields.photoPackage)} - USD 
              ${getPhotoPrice(prices, fields.photoPackage)}`}
            </h5>
            <Row
              onClick={() => this.setState({ showModalPhoto: true })}
              style={{ marginBottom: "10px" }}
            >
              <Col>
                <p style={{ color: "teal" }}>Change</p>
              </Col>
            </Row>
          </div>
        )}
        <Row>
          <Col>            
            <CheckboxInput
              name="includeDiving"
              initialValue={fields.includeDiving}
              onAttributeUpdate={this.updateAttributes}
              label={
                // <h4>Include Diving - USD {getDivingPrice(prices, 1)} / pax</h4>
                <h4>Include Diving</h4>
              }
            />
            <i><p style={{color: 'red'}}> notes: Diving Cost will be charged later. </p></i>
          </Col>
        </Row>
        {fields.includeDiving && fields.diverCount && (
          <div>
            <Row>
              <Col lg={4}>
                <DropdownInput
                  name="diverCount"
                  label="Number of Divers"
                  dropdownItems={range(1, this.props.totalGuest)}
                  initialValue={fields.diverCount}
                  onAttributeUpdate={this.updateAttributes}
                />
              </Col>
            </Row>
            {this.renderDiverForms(
              Math.min(fields.diverCount, errors.divers.length)
            )}
          </div>
        )}
        <Row>
          <Col>
            <Input
              name="specialRequest"
              label="Special Request"
              type="textarea"
              placeholder="I want..."
              initialValue={fields.specialRequest}
              error={errors.specialRequest}
              onAttributeUpdate={this.updateAttributes}
              inputStyle={{ height: "7em" }}
            />
            <p style={{ fontSize: "0.8em" }}>
              *Due to availability, we may or may not fulfill this special
              request
            </p>
          </Col>
        </Row>
        <Row
          style={{ marginBottom: "1.5em" }}
          onClick={() => this.setState({ showBreakdown: !showBreakdown })}
          className="d-block d-lg-none"
        >
          <Col>
            <Collapse in={showBreakdown}>
              <div>
                <PriceBreakdown hideTotal />
              </div>
            </Collapse>
            <Row>
              <Col style={{ fontWeight: "bold" }}>Total</Col>
              <Col xs={6} style={{ textAlign: "right", fontWeight: "bold" }}>
                USD {getTotalPrice(prices, reservation, fields)}
              </Col>
            </Row>
            <p style={{ textAlign: "right", color: "teal" }}>See details</p>
          </Col>
        </Row>
        {this.state.submitError && (
          <p style={{ color: "red" }}>
            There are errors with your data, please check the form above.
          </p>
        )}
        <Row>
          <Col>
            <Button
              onClick={this.goBack}
              style={{
                ...buttonStyle,
                float: "left",
                backgroundColor: "white"
              }}
            >
              Back
            </Button>
            <Button
              onClick={this.reserve}
              style={{ ...buttonStyle, float: "right" }}
            >
              Book Now
            </Button>
          </Col>
        </Row>
        <Modal
          centered
          show={this.state.showModalPayment}
          onHide={() => this.setState({ showModalPayment: false })}
        >
          <Modal.Header>
            <h4 style={{ textAlign: "center" }}>
              Choose payment method based on your origin
            </h4>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row className="justify-content-center">
                <Col xs="auto">
                  {/* <div style={paymentBoxStyle}>
                    <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
                      International
                    </h3>
                    <PaypalButton
                      value={getTotalPrice(prices, reservation, fields)}
                      bookcode={this.state.bookCode}
                    />
                  </div> */}
                  <p style={{'color': 'red'}}> <i>International payment will be added shortly </i></p>
                  <div style={paymentBoxStyle}>                    
                    <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
                      Indonesia
                    </h3>
                    <Button
                      style={{ width: "100%" }}
                      variant="dark"
                      onClick={this.submitLocalPayment}
                    >
                      Pay Now
                    </Button>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "0.8em",
                        margin: "0"
                      }}
                    >
                      *Payment will be in IDR
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={this.state.showModal}
          backdrop={this.state.modalDismissable ? "true" : "static"}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Body>{this.state.modalContent}</Modal.Body>
          {this.state.modalDismissable && (
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal}>
                OK
              </Button>
            </Modal.Footer>
          )}
        </Modal>
        <Modal
          centered
          size="lg"
          show={this.state.showModalPhoto}
          onHide={() => this.setState({ showModalPhoto: false })}
        >
          <Modal.Body>
            <SelectPhotoPage
              name="photoPackage"
              onAttributeUpdate={this.updateAttributes}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  fields: ownProps.fields || state.addons || initialState.fields,
  reservation: state.reservation,
  totalGuest:
    state.reservation &&
    parseInt(state.reservation.adultCount) +
      parseInt(state.reservation.childCount),
  prices: state.prices
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  putAddons: addons => dispatch(putAddons(addons))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddonsForm)
);

const buttonStyle = {
  backgroundColor: "rgba(182, 243, 232, 1)",
  border: "solid rgba(182, 243, 232, 1) 2px",
  borderRadius: "50px",
  height: "50px",
  width: "142px",
  color: "black",
  fontWeight: "bold"
};

const paymentBoxStyle = {
  margin: "25px 0",
  width: "300px",
  border: "3px dashed teal",
  borderRadius: "40px",
  padding: "30px"
};
