import moment from "moment";

export function makeReservationPayload(reservation, addons) {
  return {
    contactname: reservation.contactName,
    guestname: reservation.guestName,
    email: reservation.email,
    contactphone: reservation.countryCode + reservation.phone,
    identityno: reservation.identityNo,
    booktime: new Date().getTime(),
    checkindate: moment(reservation.checkinDate).format("YYYY-MM-DD"),
    checkoutdate: reservation.checkoutDate
      ? moment(reservation.checkoutDate).format("YYYY-MM-DD")
      : null,
    adultquantity: reservation.adultCount,
    childquantity: reservation.childCount,
    triptype: reservation.tripType,
    includediving: addons.includeDiving,
    divercount: addons.diverCount,
    includephoto: addons.includePhoto,
    photopackage: addons.photoPackage,
    specialrequest: addons.specialRequest
  };
}

export function makePersonsPayload(bookCode, addons) {
  return {
    bookcode: bookCode,
    data: addons.divers.map(d => ({
      name: d.name,
      footsize: d.feetSize,
      wetsuitsize: d.wetSuitSize,
      licensetype: d.license
    }))
  };
}
