export const PUT_RESERVATION = "PUT_RESERVATION";
export const PUT_ADDONS = "PUT_ADDONS";
export const PUT_PRICES = "PUT_PRICES";
export const PUT_RESERVED_DATES = "PUT_RESERVED_DATES";

export function putReservation(reservation) {
  return { type: PUT_RESERVATION, reservation };
}

export function putAddons(addons) {
  return { type: PUT_ADDONS, addons };
}

export function putPrices(prices) {
  return { type: PUT_PRICES, prices };
}

export function putReservedDates(reservedDates) {
  return {type: PUT_RESERVED_DATES, reservedDates}
}