import { PUT_RESERVATION, PUT_ADDONS, PUT_PRICES, PUT_RESERVED_DATES } from "./actions";

export function reducer(state = {}, action) {
  switch (action.type) {
    case PUT_RESERVATION:
      return { ...state, reservation: action.reservation };
    case PUT_ADDONS:
      return { ...state, addons: action.addons };
    case PUT_PRICES:
      return { ...state, prices: action.prices };
    case PUT_RESERVED_DATES:
      return { ...state, reservedDates: action.reservedDates };
    default:
      return state;
  }
}