import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Header, Footer } from "./Template";
import AddonsForm from "../forms/addons/AddonsForm";
import PriceBreakdown from "../common/PriceBreakdown";
import Sticky from "react-sticky-el";
import "./responsive.css";

class AddonsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.reservation) this.setState({ reservationExist: true });
    else this.props.history.replace("/reservation");
  }

  render() {
    return (
      <div>
        <div className="d-none d-lg-block">
          <Header />
        </div>
        {this.state.reservationExist && (
          <Container className="container-margin">
            <Row>
              <Col lg={8} className="form-container">
                <AddonsForm />
              </Col>
              <Col lg={{ span: 4, offset: 0 }} className="d-none d-lg-block">
                <Sticky stickyStyle={{ marginTop: "130px" }} topOffset={-130}>
                  <div className="form-container">
                    <PriceBreakdown />
                  </div>
                </Sticky>
              </Col>
            </Row>
          </Container>
        )}
        <div className="d-none d-lg-block">
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  reservation: state.reservation,
  addons: state.addons
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddonsPage)
);
