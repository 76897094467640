import React from "react";
import "react-dates/initialize";
import { SingleDatePicker, DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

export default class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue ? moment(props.initialValue) : null,
      startValue: props.initialStartValue
        ? moment(props.initialStartValue)
        : null,
      endValue: props.initialEndValue ? moment(props.initialEndValue) : null,
      focused: false,
      focusedRange: null
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      error: nextProps.error
    });
  }

  onChange = value => {
    this.setState({ value });
    this.update(value);
  };

  onRangeChange = ({ startDate, endDate }) => {
    this.setState({ startValue: startDate, endValue: endDate });
    this.rangeUpdate({ startDate, endDate });
  };

  onFocus = ({ focused }) => this.setState({ focused });
  onFocusRange = focusedRange => this.setState({ focusedRange });

  update = newValue => {
    let dict = {};
    dict[this.props.name] = newValue || this.state.value;
    this.props.onAttributeUpdate(dict);
  };

  rangeUpdate = ({ startDate, endDate }) => {
    let dict = {};
    dict[this.props.startName] = startDate || this.state.startValue;
    dict[this.props.endName] = endDate || this.state.endValue;
    this.props.onAttributeUpdate(dict);
  };

  renderInput = () => {
    const { value, startValue, endValue, focused, focusedRange } = this.state;
    const { type, name, startName, endName, dateBlock } = this.props;

    if (type === "single")
      return (
        <SingleDatePicker
          // style={{
          //   ...{ ...inputDefaultStyle, ...inputStyle },
          //   ...{ borderColor: error && "red" }
          // }}
          // className={inputClass}
          id={name}
          date={value}
          focused={focused}
          onDateChange={this.onChange}
          onFocusChange={this.onFocus}
          isDayBlocked={dateBlock}
          numberOfMonths={window.screen.width >= 768 ? 2 : 1}
          // withFullScreenPortal={window.screen.width >= 768 ? false : true}
          // orientation={window.screen.width >= 768 ? "horizontal" : "vertical"}
        />
      );
    else if (type === "range")
      return (
        <DateRangePicker
          // style={{
          //   ...{ ...inputDefaultStyle, ...inputStyle },
          //   ...{ borderColor: error && "red" }
          // }}
          // className={inputClass}
          startDateId={startName}
          endDateId={endName}
          startDate={startValue}
          endDate={endValue}
          focusedInput={focusedRange}
          onDatesChange={this.onRangeChange}
          onFocusChange={this.onFocusRange}
          isDayBlocked={dateBlock}
          numberOfMonths={window.screen.width >= 768 ? 2 : 1}
          // withFullScreenPortal={window.screen.width >= 768 ? false : true}
          // orientation={window.screen.width >= 768 ? "horizontal" : "vertical"}
        />
      );
    else throw Error("invalid type for DateInput");
  };

  render() {
    const { error } = this.state;
    const {
      label,
      containerStyle,
      containerClass,
      labelStyle,
      labelClass,
      errorStyle
    } = this.props;
    return (
      <div
        style={containerStyle || containerDefaultStyle}
        className={containerClass}
      >
        <label style={labelStyle || labelDefaultStyle} className={labelClass}>
          {label}
        </label>
        {this.renderInput()}
        <p style={errorStyle || errorDefaultStyle}>{error ? error : ""}</p>
      </div>
    );
  }
}

const labelDefaultStyle = {
  textAlign: "left",
  display: "block"
};

// const inputDefaultStyle = {
//   color: "rgba(59, 58, 58, 0.55)",
//   display: "block",
//   width: "100%",
//   height: "3em",
//   borderWidth: "1px",
//   borderStyle: "solid",
//   borderColor: "rgba(59, 58, 58, 0.55)",
//   backgroundColor: "transparent",
//   paddingLeft: "0.5em",
//   textAlign: "top"
// };

const containerDefaultStyle = {
  // overflow: "auto",
  display: "block"
};

const errorDefaultStyle = {
  color: "red",
  minHeight: "1em",
  fontSize: "0.8em",
  marginTop: "3px",
  marginBottom: "1em",
  textAlign: "left",
  width: "70%"
};
