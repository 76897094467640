import React from "react";
import { apiHost, paypalClientKey } from "../../../env";
import { PayPalButton } from "react-paypal-button-v2";
import { withRouter } from "react-router-dom";

class PaypalButton extends React.Component {
  render() {
    return (
      <PayPalButton
        amount={this.props.value}
        options={{
          clientId: paypalClientKey
        }}
        onSuccess={async (details, data) => {
          alert("Transaction completed by " + details.payer.name.given_name);

          // OPTIONAL: Call your server to save the transaction
          let payload = {
            order_id: data.orderID,
            gross_amount: details.purchase_units[0].amount.value,
            transaction_time: details.create_time,
            book_code: this.props.bookcode
          };
          await fetch(apiHost + "/paypal/notif", {
            method: "post",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify(payload)
          });
          this.props.history.push("/thankyou");
        }}
      />
    );
  }
}

export default withRouter(PaypalButton);
