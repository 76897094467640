import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Input from "./Input";
import { Sizes } from "../../utils/Constants";
import DropdownInput from "./DropdownInput";

class Dive extends Component {
  render() {
    let { idx, initialValue, error } = this.props;

    let onAttributeUpdate = newAttributes => {
      let divers = [];
      divers[idx] = newAttributes;
      this.props.onAttributeUpdate({ divers });
    };

    return (
      <div>
        <Row>
          <Col lg={1} xs={12}>
            <div className="d-none d-lg-block" style={{ paddingTop: "2.5em" }}>
              <h3>{idx + 1}.</h3>
            </div>
            <div className="d-block d-lg-none" style={{ paddingTop: "1em" }}>
              <h3>Diver {idx + 1}</h3>
            </div>
          </Col>
          <Col>
            <Row>
              <Col lg={8} xs={7}>
                <Input
                  name="name"
                  label="Name"
                  type="text"
                  initialValue={initialValue.name}
                  error={error.name}
                  onAttributeUpdate={onAttributeUpdate}
                />
              </Col>
              <Col>
                <Input
                  name="license"
                  label="License (if any)"
                  type="text"
                  initialValue={initialValue.license}
                  error={error.license}
                  onAttributeUpdate={onAttributeUpdate}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3} xs={4}>
                <DropdownInput
                  name="wetSuitSize"
                  label="Wet Suit Size"
                  dropdownItems={Sizes}
                  initialValue={initialValue.wetSuitSize}
                  onAttributeUpdate={onAttributeUpdate}
                />
              </Col>
              <Col lg={3} xs={4}>
                <DropdownInput
                  name="feetSize"
                  label="Feet Size"
                  dropdownItems={Sizes}
                  initialValue={initialValue.feetSize}
                  onAttributeUpdate={onAttributeUpdate}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dive;
