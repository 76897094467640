export default {
  fields: {
    tripType: 'liveAboard',
    contactName: null,
    guestName: null,
    email: null,
    countryCode: "62",
    phone: null,
    identityNo: null,
    adultCount: 1,
    childCount: 0,
    checkinDate: null,
    checkoutDate: null
  },
  errors: {}
};
