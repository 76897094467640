import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { apiHost } from "../../env";
import React, { Component } from "react";
import { Header, Footer } from "./Template";

const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const inputStyle = {
  margin: '15px',
  paddingTop: '4px',
  paddingBottom: '4px'
}

export default class AdminPage extends Component {
  constructor(props){
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.showData = this.showData.bind(this);
    this.state = {
      data: "null",
      inputCodeBook: "",
      message: ""
    }  
  }

  showDataDiving = (personObj) => {
    return (
      <div>
        {personObj.map(function(d, idx){
         return (
            <ul>
              <p> {d.name }</p>
              <li key={idx}>License Type: {d.licensetype}</li>
              <li key={idx}>Foot size: {d.footsize}</li>
              <li key={idx}>Wetsuit size: {d.wetsuitsize}</li>              
           </ul>
           )
       })}
       
      </div>
    )
  }

  showData = () => {
    let dataShow = false;    
    if (this.state.data != "null") {dataShow = true}
    return (
      <div>
        <p> {this.state.message }</p>
        { 
          dataShow && <ul>
            <li> Contact Name: {this.state.data.contactname} </li>
            <li> Guest Name: {this.state.data.guestname} </li>
            <li> Trip Type: {this.state.data.triptype} </li>
            <li> Email: {this.state.data.email} </li>
            <li> Contact: {this.state.data.contactphone} </li>
            <li> Identity: {this.state.data.identityno} </li>
            {/* <li> Booking Time: {new Date(this.state.data.booktime).toDateString()} </li>             */}
            <li> Check In: {new Date(this.state.data.checkindate).toLocaleDateString('id-ID', dateOptions)} </li>
            {this.state.data.checkoutdate !== null && <li> Check Out: {new Date(this.state.data.checkoutdate).toLocaleDateString('id-ID', dateOptions)} </li>} 
            <li> Adult quantity: {this.state.data.adultquantity} </li>
            <li> Child quantity: {this.state.data.childquantity} </li>
            <li> Include Photo: {this.state.data.includephoto === 1 ? "yes": "no"} </li>
            { this.state.data.includephoto === 1 && <li> Photo Package: {this.state.data.photopackage} </li> }
            <li> Include Diving: {this.state.data.includediving === 1 ? "yes": "no"} </li>
            { this.state.data.includediving === 1 && <li> Diving Count: {this.state.data.divercount} </li> }
            <li> Special Request: {this.state.data.specialrequest} </li>
            <li> <b> Total Price: {this.state.data.totalprice} </b> </li>
            <li> Already Paid: {this.state.data.ispaid === 1 ? "yes": "no"} </li>
            { this.state.data.ispaid === 1 && <li> Payment Time: {new Date(this.state.data.paymenttime).toLocaleDateString('id-ID', dateOptions)} </li> }
            <li> Diving Detail: {this.showDataDiving(this.state.data.person)}</li>
          </ul> 
      }
    </div>
    )
  }

  onChange = e => {
    this.setState({ inputCodeBook: e.target.value });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      let url = apiHost + "/reservations/" + this.state.inputCodeBook;
      let custDetailRes = await fetch(url);
      if (!custDetailRes.ok) throw Error("error on " + url);

      let custDetail = await custDetailRes.json();
      if (custDetail.data.status === "400") {
        this.setState({data: {"data": "null"}, isSuccess: false, message: "Data tidak ditemukan"})
      } else {
        this.setState({data: custDetail.data, isSuccess: true, message: "Data Customer berhasil ditemukan"})
      }      
    } catch (error) {
      console.log(error);  
      this.setState({
        "isSuccess": false,
        message: "Data tidak ditemukan",        
      })
    }
  }

  render() {
    return (
      <div>
        <Header />
        <Container className="container-margin">
          <Row>
            <Col lg={12} className="form-container">
              <div>
                <form onSubmit={this.onSubmit}>
                  <input 
                    type="text" 
                    name="searchCodebook"
                    label="search Codebook"
                    placeholder="Enter codebook"
                    onChange={ this.onChange } 
                    style = {inputStyle}
                  />      
                  <Button
                    value="submit"
                    variant="secondary"
                    onClick={this.onSubmit}
                  >
                    Search
                  </Button>
                </form>                
              </div>
              <div className="table">
                { this.showData() }              
              </div>
            </Col>
          </Row>
        </Container>   
        <Footer />    
      </div>
    );
  }
}
