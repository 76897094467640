import { PhotoPackageItems, TripTypeItems } from "./Constants";

export function convertToIDR(value, convertionRate) {
  return Math.ceil(convertionRate * value / 10000) * 10000;
}

export function range(start, stop) {
  let result = [];
  for (let i = start; i <= stop; i++) {
    result.push(i);
  }
  return result;
}

export const getPhotoPackageLabel = value => {
  let filtered = PhotoPackageItems.filter(i => i.value === value);
  return filtered.length ? filtered[0].label : null;
};

export const getTripTypeLabel = value => {
  let filtered = TripTypeItems.filter(i => i.value === value);
  return filtered.length ? filtered[0].label : null;
};

export const getTripPrice = (prices, tripType) => {
  return prices.filter(
    p => p["item"] === "triptype" && p["subitem"] === tripType
  )[0]["price"];
};

export const getPhotoPrice = (prices, photoPackage) => {
  return prices.filter(
    p => p["item"] === "photopackage" && p["subitem"] === photoPackage
  )[0]["price"];
};

export const getDivingPrice = (prices, diverCount) => {
  return diverCount * prices.filter(p => p["item"] === "diver")[0]["price"];
};

export const getTotalPrice = (prices, reservation, addons = null) => {
  let total = 0;
  if (reservation) total += getTripPrice(prices, reservation.tripType);
  if (addons) {
    if (addons.includePhoto && addons.photoPackage)
      total += getPhotoPrice(prices, addons.photoPackage);
    if (addons.includeDiving && addons.diverCount)
      total += getDivingPrice(prices, addons.diverCount);
  }
  return total;
};

export const compileReservedDates = original => {
// Live Aboard	- Day = no
// Live Aboard	- Sunset = no
// Live Aboard - Dinner = no

// Day - Live Aboard = no
// Day - Sunset = no
// Day - Dinner = oke

// Sunset - Live Aboard = no
// Sunset - Day = no
// Sunset - Dinner = oke

// Dinner - Sunset = oke
// Dinner - Live Aboard = no
// Dinner - Day = oke
  let compiled = {
    dayCruise: original["dayCruise"].concat(original["liveAboard"]).concat(original["sunsetCruise"]),
    sunsetCruise: original["sunsetCruise"].concat(original["liveAboard"]).concat(original["dayCruise"]),
    dinnerCruise: original["dinnerCruise"].concat(original["liveAboard"]),
    liveAboard: Object.values(original).reduce((arr, og) => arr.concat(og), [])
  };
  return compiled;
};
