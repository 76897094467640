export function validators(newAttributes) {
  return Object.keys(newAttributes).reduce((errors, attribute) => {
    if (attribute === "divers") {
      let errors2 = newAttributes[attribute].map(element => {
        let errors3 = Object.keys(element).reduce((errors, attribute3) => {
          errors[attribute3] =
            attributeValidators[attribute][attribute3] &&
            attributeValidators[attribute][attribute3](element[attribute3]);
          return errors;
        }, {});
        return errors3;
      });
      errors[attribute] = errors2;
    } else {
      errors[attribute] =
        attributeValidators[attribute] &&
        attributeValidators[attribute](newAttributes[attribute]);
    }
    return errors;
  }, {});
}

const attributeValidators = {
  divers: {
    name: val => !val && 'required'
  }
};
