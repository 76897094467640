import React, { Component } from "react";

const frame = '<iframe src="https://iqbalmotoworldbali.wixsite.com/pinisi" style="    position: fixed;    left: 0px;    top: -53px;    width: 100%;    height: 108%;}"></iframe>'

export default class HomePage extends Component {
  render() {
    return (
      <div dangerouslySetInnerHTML={{__html:frame}}>
      </div>
    );
  }
}
