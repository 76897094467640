import React, { Component } from "react";
import { Header, Footer } from "./Template";
import "./ThankYouPage.css";
import { Container } from "react-bootstrap";

export default class ThankYouPage extends Component {
  render() {
    return (
      <div>
        <div className="d-none d-lg-block">
          <Header />
        </div>
        <Container>
          <div className="thank-you">
            <h2 className="">Thank You For Using Our Service!</h2>
            <h3> The payment has been made and the transaction is completed</h3>
          </div>
          <div className="ship-wrap">
            <div className="wave1 wave" />
            <div className="wave2 wave" />
            <div className="ship">
              <div className="main">
                <div className="main-mast" />
                <div className="main-course" />
                <div className="fore-course" />
                <div className="flag" />
              </div>
            </div>
          </div>

          <div className="footnotes">
            <p className="lead">
              <strong>Please check your email</strong> for the booking code and
              a receipt for your purchase in Inbox or Spam/Trash folder and
              please don't forget to bring ID card on you happy day!
            </p>
            <p>
              Having trouble? <a href="/contactus">Contact us</a>
            </p>
            <p className="lead">
              <a className="button" href="/" role="button">
                Continue to homepage
              </a>
            </p>
          </div>
        </Container>
        <div className="d-none d-lg-block">
          <Footer />
        </div>
      </div>
    );
  }
}
