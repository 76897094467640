import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { getTripPrice } from "../../utils/helpers";
import { connect } from "react-redux";

class TripTypeCard extends Component {
  render() {
    return (
      <div style={{ marginBottom: "20px" }}>
        <div>
          <img width="175px" height="120px" src={this.props.img} alt="cover" />
        </div>
        <div>
          <h4 style={{ marginTop: "10px" }}>{this.props.title}</h4>
          <ul>
            {this.props.content.map(c => (
              <li key={c}>{c}</li>
            ))}
          </ul>
        </div>
        <Row className="justify-content-center">
          <Col xs="auto">
            <h5>USD {getTripPrice(this.props.prices, this.props.value)}</h5>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <button style={buttonStyle} onClick={this.props.onSelect}>
              Select
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  prices: state.prices
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TripTypeCard);

const buttonStyle = {
  backgroundColor: "rgba(182, 243, 232, 1)",
  border: "solid rgba(182, 243, 232, 1) 0px",
  borderRadius: "50px",
  height: "50px",
  width: "142px",
  color: "black",
  fontWeight: "bold",
  margin: "0 auto"
};
