import React, { Component } from "react";
import Router from "./Router";
import { Provider } from "react-redux";
import { reducer } from "./utils/reducers";
import { createStore } from "redux";
import './App.css'

const store = createStore(reducer);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router />
      </Provider>
    );
  }
}

export default App;
