import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReservationForm from "../forms/reservation/ReservationForm";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { Header, Footer } from "./Template";
import PriceBreakdown from "../common/PriceBreakdown";
import Sticky from "react-sticky-el";
import "./responsive.css";
import { apiHost } from "../../env";
import { putPrices, putReservedDates } from "../../utils/actions";
import { compileReservedDates } from "../../utils/helpers";

class ReservationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  async componentDidMount() {
    if (this.props.prices) {
      this.setState({ gotInitialData: true });
      return;
    }

    this.setState({
      showModal: true,
      modalContent: "Please wait while we prepare your form...",
      modalDismissable: false
    });

    try {
      let pricesRes = await fetch(apiHost + "/prices");
      let reservedDatesRes = await fetch(apiHost + "/reservedDates");

      if (!pricesRes.ok) throw Error("error on /prices");
      if (!reservedDatesRes.ok) throw Error("error on /reservedDates");

      let pricesData = await pricesRes.json();
      let reservedDatesData = await reservedDatesRes.json();

      this.props.putPrices(pricesData.data);
      this.props.putReservedDates(compileReservedDates(reservedDatesData.data));

      this.setState({
        showModal: false,
        gotInitialData: true
      });
    } catch (error) {
      this.setState({
        showModal: true,
        modalContent:
          "There is an error in our page. Please contact our customer support.",
        modalDismissable: true
      });
    }
  }

  render() {
    return (
      <div>
        {/* <div style={bannerImage}>
          <div
            style={{
              backgroundColor: "white",
              opacity: "0.5",
              height: "262px"
            }}
          >
            <p style={bannerTitle}>You Can't Buy Happiness</p>
            <p style={bannerSubtitle}>But you can book BEHIKE!</p>
          </div>
        </div> */}
        <div className="d-none d-lg-block">
          <Header />
        </div>
        {this.state.gotInitialData && (
          <Container className="container-margin">
            <Row>
              <Col lg={8} className="form-container">
                <ReservationForm />
              </Col>
              <Col lg={{ span: 4, offset: 0 }} className="d-none d-lg-block">
                <Sticky stickyStyle={{ marginTop: "130px" }} topOffset={-130}>
                  <div className="form-container">
                    <PriceBreakdown />
                  </div>
                </Sticky>
              </Col>
            </Row>
          </Container>
        )}
        <div className="d-none d-lg-block">
          <Footer />
        </div>
        <Modal
          centered
          show={this.state.showModal}
          backdrop={this.state.modalDismissable ? "true" : "static"}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Body>{this.state.modalContent}</Modal.Body>
          {this.state.modalDismissable && (
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.props.history.push("/")}
              >
                OK
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  reservation: state.reservation,
  prices: state.prices
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  putPrices: prices => dispatch(putPrices(prices)),
  putReservedDates: reservedDates => dispatch(putReservedDates(reservedDates))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReservationPage)
);

// const bannerImage = {
//   height: "262px",
//   backgroundImage: "url('/asset/images/reservation-banner.jpg')",
//   backgroundSize: "cover",
//   marginBottom: "30px"
// };

// const bannerTitle = {
//   paddingTop: "65px",
//   textAlign: "center",
//   color: "white",
//   fontFamily: "avenir-lt-w01_35-light1475496,sans-serif",
//   fontSize: "60px",
//   letterSpacing: "6px",
//   fontWeight: "400",
//   lineHeight: "66px"
// };

// const bannerSubtitle = {
//   textAlign: "center",
//   color: "white",
//   fontFamily: "avenir-lt-w01_35-light1475496,sans-serif",
//   fontSize: "28px"
// };
